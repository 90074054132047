import User from "../../models/user";
import {useEffect, useState} from "react";
import UserServices from "../../services/UserServices";
import Typography from "../../components/global/Typography";
import {styled, useDisclosure} from "@chakra-ui/react";
import CustomButton from "../../components/global/button/Button";
import {HiPlus} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import {FaUserAlt} from "react-icons/fa";
import UserCard from "../../components/global/card/UserCard";
import UserCreateDrawer from "../../components/global/drawer/User/UserCreateDrawer";
import SearchBar from "../../components/global/input/SearchBar/SearchBar";
import PageTemplate from "../../components/global/views/PageTemplate";

const PageContentHeader = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
  }
})

const PageContentBody = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "60%",
    marginTop: "20px",
  }
})

function UsersLanding() {

  const {t} = useTranslation();
  const {isOpen, onOpen, onClose} = useDisclosure()

  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    UserServices.getAllUsers()
      .then((response) => setUsers([...response].sort((a, b) => a.last_name.localeCompare(b.last_name))))
      .catch(() => setUsers([]))
      .finally(() => setLoading(false));
  }, [onClose]);

  return (
    <PageTemplate style={{minWidth:"500px"}}>
      <PageContentHeader>
        <Typography variant="title">{t("pages.users.list.title")}</Typography>
        <CustomButton onClick={onOpen} icon={<HiPlus/>}>
          {t("pages.users.list.add")}
        </CustomButton>
      </PageContentHeader>

      <SearchBar
        placeholder={t("pages.users.list.search") as string}
        icon={<FaUserAlt color="#918D86"/>}
        styleSearchBar={{width: "60%", minWidth: "300px", height: "40px", marginTop: "20px", marginBottom: "2"}}
      />

      <PageContentBody>
        {loading ? (
          <Typography variant="body">{t("pages.users.list.loading")}</Typography>
        ) : (
          <div style={{width: "100%"}}>
            {users.map((user) => <UserCard user={user} key={JSON.stringify(user)}
                                           setUsers={setUsers} users={users}/>)}
          </div>
        )}
      </PageContentBody>

      <UserCreateDrawer
        isOpen={isOpen}
        onClose={onClose}
        users={users}
        setUsers={setUsers}
      />
    </PageTemplate>
  )
}

export default UsersLanding;