import {Avatar, IconButton, styled} from "@chakra-ui/react";
import {AiOutlineRight, AiOutlineUser} from "react-icons/ai";
import {ReactNode} from "react";

const CardItem = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "70px",
    borderRadius: "8px",
    borderColor: "#918D86",
    borderWidth: "1px",
    marginTop: "5px",
    marginBottom: "5px",
  }
})

const CardItemLeft = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "20px",
  }
})

const CardText = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
  }
})

const CardTitle = styled("div", {
  baseStyle: {
    display: "flex",
    fontWeight: "regular",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
    fontSize: "15px",
    lineHeight: "20px",
  }
})

const CardSubtitle = styled("div", {
  baseStyle: {
    display: "flex",
    fontWeight: "regular",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
    fontSize: "12px",
    lineHeight: "14px",
  }
})

const CardItemRight = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "20px",
  }
})

interface SimpleCardProps {
  name: string;
  srcAvatar?: any;
  title: ReactNode;
  subtitle: ReactNode;
  onOpen?: () => void;
}

function SimpleCard({
                    name,
                    srcAvatar,
                    title,
                    subtitle,
                    onOpen,
                  }: SimpleCardProps) {

  return (
    <CardItem>
      <CardItemLeft>
        <Avatar
          size="md"
          name={name}
          src={srcAvatar || undefined}
          bg="#F5F3ED"
          color="#918D86"
          icon={<AiOutlineUser fontSize='1.5rem'/>}
        />
        <CardText>
          <CardTitle>
            {title}
          </CardTitle>
          <CardSubtitle>
            {subtitle}
          </CardSubtitle>
        </CardText>
      </CardItemLeft>

      <CardItemRight>
        <IconButton
          variant='outline'
          color='#918D86'
          borderColor='#918D86'
          aria-label='Open user'
          fontSize='20px'
          icon={<AiOutlineRight/>}
          onClick={onOpen}
        />
      </CardItemRight>

    </CardItem>
  )
}

export default SimpleCard;