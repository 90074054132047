import ProtectedPageTemplate from "../ProtectedPageTemplate";
import {Route, Routes} from "react-router-dom";
import {ROUTES} from "../../utils/consts/router";
import HomeLanding from "./HomeLanding";
import Profile from "../profile/Profile";
import Users from "../users/Users";
import MissionsLanding from "../missions/MissionsLanding";
import MissionsDetails from "../missions/MissionsDetails";
import Company from "../company/Company";

function Home() {

  return(
    <ProtectedPageTemplate>
      <Routes>
        <Route path={ROUTES.HOME_CHILD} element={<HomeLanding/>}/>
        <Route path={ROUTES.USERS} element={<Users/>}/>
        <Route path={ROUTES.PROFILE} element={<Profile/>}/>
        <Route path={ROUTES.MISSIONS_CHILD} element={<MissionsLanding/>}/>
        <Route path={ROUTES.MISSIONS_DETAIL_CHILD} element={<MissionsDetails/>}/>
        <Route path={ROUTES.COMPANY} element={<Company/>}/>
      </Routes>
    </ProtectedPageTemplate>
  )
}

export default Home;