import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled,
  useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import InputIcon from "../../global/input/InputIcon";
import {BiRename} from "react-icons/bi";
import DrawerFooterGeneric from "../DrawerFooterGeneric";
import Job from "../../../models/job";
import JobServices from "../../../services/JobService";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

const CustomDrawerBody = styled(DrawerBody, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    width: "100%",
  }
})

interface JobCreateDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  jobs: Job[];
  setJobs: (jobs: Job[]) => void;
}

function JobCreateDrawer({
                            isOpen,
                            onClose,
                            jobs,
                            setJobs,
                          }: JobCreateDrawerProps) {

  const {t} = useTranslation();
  const toast = useToast();

  const [newJob, setNewJob] = useState<Job>(new Job());
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setNewJob(new Job())
  }, [isOpen]);

  const handleCreateJob = () => {
    if (!newJob.name) {
      toast({
        title: t("labels.error"),
        description: t("pages.my_company.jobs.create.error_empty_name"),
        status: "error",
        duration: 4500,
      })
      return;
    }
    setLoading(true)
    JobServices.create(newJob)
      .then((job) => {
        setJobs([...jobs, job].sort((a, b) => a.name.localeCompare(b.name)))
        setLoading(false)
        toast({
          title: t("pages.my_company.jobs.create.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
        onClose()
      })
      .catch(
        (error) => {
          toast({
            title: t("labels.error"),
            description: t(`pages.my_company.jobs.create.${error.detail}`),
            status: "error",
            duration: 4500,
          })
        }
      ).finally(() => {
      setLoading(false)
    });
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.my_company.jobs.create.title")}
        </CustomDrawerHeader>

        <CustomDrawerBody paddingTop={"30px"}>

          <InputIcon
            icon={<BiRename color="#918D86"/>}
            placeholder={t("labels.name") as string}
            value={newJob.name as string}
            onChange={(e) => setNewJob({...newJob, name: e.target.value} as Job)}
            type="text"
          />

          <InputIcon
            icon={<BiRename color="#918D86"/>}
            placeholder={t("labels.description") as string}
            value={newJob.description as string}
            onChange={(e) => setNewJob({...newJob, description: e.target.value} as Job)}
            type="textArea"
          />

        </CustomDrawerBody>

        <DrawerFooterGeneric onClose={onClose}
                             onSave={() => handleCreateJob()}
                             loading={loading}/>


      </DrawerContent>
    </Drawer>
  )
}

export default JobCreateDrawer;