import React, {useState} from "react";
import InputIcon from "../../../global/input/InputIcon";
import {BiRename} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {DrawerBody, styled, useToast} from "@chakra-ui/react";
import DrawerFooterGeneric from "../../DrawerFooterGeneric";
import Group from "../../../../models/group";
import GroupServices from "../../../../services/GroupService";
import {RiDeleteBinLine} from "react-icons/ri";

interface RankInformationDrawerContentProps {
  groupId: string;
  onClose: () => void;
  groups: Group[];
  setGroups: (groups: Group[]) => void;
}

function GroupInformationDrawerContent({
                                         groupId,
                                         onClose,
                                         groups,
                                         setGroups,
                                       }: RankInformationDrawerContentProps) {

  const {t} = useTranslation();
  const toast = useToast();
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [selectedGroup, editSelectedGroup] = useState<Group>(groups.find((r) => r.id === groupId) || new Group())

  const handleUpdateGroup = (updatedGroup: Group) => {
    setLoadingSave(true)
    GroupServices.update(updatedGroup, updatedGroup.id)
      .then((res) => {
        setGroups(
          [...groups.filter((r) => r.id !== updatedGroup.id), res]
            .sort((a, b) => a.name.localeCompare(b.name))
        )
        toast({
          title: t("pages.my_company.groups.edit.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
      })
      .catch((error) => {
        toast({
          title: t("labels.error"),
          description: t(`pages.my_company.groups.edit.${error.detail}`),
          status: "error",
          duration: 4500,
        })
      })
      .finally(() => {
        setLoadingSave(false)
        onClose()
      })
  }

  const handleDeleteGroup = (deleteGroupId: Group["id"]) => {
    setLoadingDelete(true)
    GroupServices.delete(deleteGroupId)
      .then(() => {
        setGroups([...groups.filter((r) => r.id !== deleteGroupId)])
        toast({
          title: t("pages.my_company.groups.delete.success"),
          status: "success",
          duration: 2000,
          isClosable: true,
        })
      })
      .catch((error) => {
        toast({
          title: t("labels.error"),
          description: t(`pages.my_company.groups.delete.${error.detail}`),
          status: "error",
          duration: 4500,
        })
      })
      .finally(() => {
        setLoadingDelete(false)
      }
    )
  }

  return (
    <>
      <DrawerBody paddingTop={"30px"}>
        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.name") as string}
          value={selectedGroup.name}
          onChange={(e) => editSelectedGroup({...selectedGroup, name: e.target.value} as Group)}
          type="text"
        />

        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.description") as string}
          value={selectedGroup.description}
          onChange={(e) => editSelectedGroup({
            ...selectedGroup,
            description: e.target.value
          } as Group)}
          type="text"
        />

      </DrawerBody>
      <DrawerFooterGeneric onClose={onClose}
                           onSave={() => handleUpdateGroup(selectedGroup)}
                           loading={loadingSave}
                           onAction={() => handleDeleteGroup(selectedGroup.id)}
                           labelAction={t("labels.delete") as string}
                           styleAction={{backgroundColor: "#d25353", color: "#F5F3ED"}}
                           colorAction={"#F5F3ED"}
                           loadingAction={loadingDelete}
                           actionIcon={<RiDeleteBinLine/>}/>
    </>)
}

export default GroupInformationDrawerContent;