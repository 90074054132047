import React, {useState} from "react";
import Rank from "../../../../models/rank";
import InputIcon from "../../../global/input/InputIcon";
import {BiRename} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {DrawerBody, styled, useToast} from "@chakra-ui/react";
import JobImage from "../../../../assets/img/job.png";
import RankServices from "../../../../services/RankServices";
import DrawerFooterGeneric from "../../DrawerFooterGeneric";
import {RiDeleteBinLine} from "react-icons/ri";

const Title = styled("div", {
  baseStyle: {
    fontSize: "22px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

interface RankInformationDrawerContentProps {
  rankId: string;
  onClose: () => void;
  ranks: Rank[];
  setRanks: (ranks: Rank[]) => void;
}

function RankInformationDrawerContent({
                                        rankId,
                                        onClose,
                                        ranks,
                                        setRanks,
                                      }: RankInformationDrawerContentProps) {

  const {t} = useTranslation();
  const toast = useToast();
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [selectedRank, editSelectedRank] = useState<Rank>(ranks.find((r) => r.id === rankId) || new Rank())

  const handleUpdateRank = (updatedRank: Rank) => {
    setLoadingSave(true)
    RankServices.update(updatedRank, updatedRank.id)
      .then((res) => {
        setRanks(
          [...ranks.filter((r) => r.id !== updatedRank.id), res]
            .sort((a, b) => a.name.localeCompare(b.name))
        )
        toast({
          title: t("pages.my_company.ranks.edit.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
      })
      .catch(
        (error) => {
          toast({
            title: t("labels.error"),
            description: t(`pages.my_company.ranks.edit.${error.detail}`),
            status: "error",
            duration: 4500,
          })
        }
      )
      .finally(() => {
        setLoadingSave(false)
        onClose()
      })
  }

  const handleDeleteRank = (deleteRankId: Rank["id"]) => {
    setLoadingDelete(true)
    RankServices.delete(deleteRankId)
      .then(() => {
        setRanks([...ranks.filter((r) => r.id !== deleteRankId)])
        toast({
          title: t("pages.my_company.ranks.delete.success"),
          status: "success",
          duration: 2000,
          isClosable: true,
        })
      })
      .catch((error) => {
        toast({
          title: t("labels.error"),
          description: t(`pages.my_company.ranks.delete.${error.detail}`),
          status: "error",
          duration: 4500,
        })
      })
      .finally(() => {
        setLoadingSave(false)
        onClose()
      })
  }
  return (
    <>
      <DrawerBody>

        <img src={JobImage} alt="Add User"
             style={{height: "250px", width: "375px"}}/>

        <div style={{height: "30px"}}/>

        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.name") as string}
          value={selectedRank.name}
          onChange={(e) => editSelectedRank({...selectedRank, name: e.target.value} as Rank)}
          type="text"
        />

        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.description") as string}
          value={selectedRank.description}
          onChange={(e) => editSelectedRank({
            ...selectedRank,
            description: e.target.value
          } as Rank)}
          type="text"
        />

        <Title marginTop={"2vw"}>
          {t("pages.my_company.ranks.information.groups")}
        </Title>

        <Title marginTop={"2vw"}>
          {t("pages.my_company.ranks.information.permissions")}
        </Title>

      </DrawerBody>
      <DrawerFooterGeneric onClose={onClose}
                           onSave={() => handleUpdateRank(selectedRank)}
                           loading={loadingSave}
                           onAction={() => handleDeleteRank(selectedRank.id)}
                           labelAction={t("labels.delete") as string}
                           styleAction={{backgroundColor: "#d25353", color: "#F5F3ED"}}
                           colorAction={"#F5F3ED"}
                           loadingAction={loadingDelete}
                           actionIcon={<RiDeleteBinLine/>}/>
    </>
  )
}

export default RankInformationDrawerContent;