import SimpleCard from "./SimpleCard";
import {useDisclosure} from "@chakra-ui/react";
import Group from "../../../models/group";
import GroupInformationDrawer
  from "../../company/Group/GroupInformationDrawer/GroupInformationDrawer";

interface GroupCardProps {
  group: Group;
  groups: Group[];
  setGroups: (groups: Group[]) => void;
}

function GroupCard({
                    group,
                    groups,
                    setGroups,
                  }: GroupCardProps) {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <SimpleCard
        name={group.name}
        title={group.name}
        subtitle={group.description}
        onOpen={() => onOpen()}
      />

      <GroupInformationDrawer groupId={group.id} isOpen={isOpen} onClose={onClose} groups={groups} setGroups={setGroups}/>
    </>
  )
}

export default GroupCard;