import {Route, Routes, useNavigate} from "react-router-dom";
import CompanyGeneral from "./CompanyGeneral";
import Typography from "../../components/global/Typography";
import {styled} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {NavigationElement} from "../../types/navigation";
import {ROUTES} from "../../utils/consts/router";
import {
    AiOutlineFormatPainter,
    AiOutlineGlobal,
    AiOutlineGroup
} from "react-icons/ai";
import {GrConfigure} from "react-icons/gr";
import {GiRank3} from "react-icons/gi";
import {IoPeopleCircleOutline} from "react-icons/io5";
import CompanyCustom from "./CompanyCustom";
import CompanyConfig from "./CompanyConfig";
import CompanyRanks from "./CompanyRanks";
import CompanyJobs from "./CompanyJobs";
import {RiGroupLine} from "react-icons/ri";
import PageTemplate from "../../components/global/views/PageTemplate";
import CompanyGroups from "./CompanyGroups";
import CompanyPoles from "./CompanyPoles";

const PageContentHeader = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
  }
})

const PageContentNavigation = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
    marginTop: "20px",
  }
});

const Element = styled("div", {
  baseStyle: {
    height: "40px",
    display: "flex",
    color: "#fff",
    alignItems: "center",
    fontFamily: "Mabry Pro",
    fontWeight: "normal",
    fontSize: "15px",
    cursor: "pointer",
    marginLeft: "10px",
  }
})

const Icon = styled("div", {
  baseStyle: {
    marginRight: 2,
  }
})
const navigationElements: NavigationElement[] = [
  {
    title: "Général",
    icon: <AiOutlineGlobal style={{width: 16, height: 16}}/>,
    path: ROUTES.COMPANY_GENERAL,
  },
  {
    title: "Personnalisation",
    icon: <AiOutlineFormatPainter style={{width: 16, height: 16}}/>,
    path: ROUTES.COMPANY_CUSTOM,
  },
  {
    title: "Configuration",
    icon: <GrConfigure style={{width: 16, height: 16}}/>,
    path: ROUTES.COMPANY_ADMIN,
  },
  {
    title: "Groupes",
    icon: <AiOutlineGroup style={{width: 16, height: 16}}/>,
    path: ROUTES.COMPANY_GROUPS,
  },
  {
    title: "Rôles",
    icon: <IoPeopleCircleOutline style={{width: 16, height: 16}}/>,
    path: ROUTES.COMPANY_RANKS,
  },
  {
    title: "Pôles",
    icon: <RiGroupLine style={{width: 16, height: 16}}/>,
    path: ROUTES.COMPANY_POLES,
  },
  {
    title: "Postes",
    icon: <GiRank3 style={{width: 16, height: 16}}/>,
    path: ROUTES.COMPANY_JOBS,
  }
]

function Company() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <PageTemplate>
      <PageContentHeader>
        <Typography variant="title">{t("pages.my_company.title")}</Typography>
      </PageContentHeader>

        <PageContentNavigation style={{
            display: "flex",
            width: "80%",
            justifyContent: "space-around",
            flexWrap: "wrap",
            alignItems: "center",
        }}>
        {navigationElements.map((element, index) => {

          const current = window.location.pathname === element.path;

          return (
            <Element key={index} onClick={() => navigate(element.path)} style={{marginRight: "20px"}}>
              <Icon
                style={{
                    color: current ? "#2B2A24" : "#54524A",
                }}
              >
                {element.icon}
              </Icon>
              <div style={{
                color: current ? "#2B2A24" : "#54524A",
                textUnderlineOffset: "3px",
                textUnderlinePosition: "under",
                textDecoration: "underline",
                textDecorationColor: current ? "#54524A" : "transparent",
                textDecorationThickness: "0.1rem",
              }}
              >
                {element.title}
              </div>
            </Element>
          )
        })}
      </PageContentNavigation>

      <Routes>
        <Route path="/" element={<CompanyGeneral/>}/>
        <Route path={ROUTES.COMPANY_CUSTOM_CHILD} element={<CompanyCustom/>}/>
        <Route path={ROUTES.COMPANY_ADMIN_CHILD} element={<CompanyConfig/>}/>
        <Route path={ROUTES.COMPANY_GROUPS_CHILD} element={<CompanyGroups/>}/>
        <Route path={ROUTES.COMPANY_RANKS_CHILD} element={<CompanyRanks/>}/>
        <Route path={ROUTES.COMPANY_POLES_CHILD} element={<CompanyPoles/>}/>
        <Route path={ROUTES.COMPANY_JOBS_CHILD} element={<CompanyJobs/>}/>
      </Routes>

    </PageTemplate>
  )
}

export default Company;