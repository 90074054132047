export default class Rank {
  id: string;
  company: string;
  name: string;
  description: string;

  constructor({
                id = "",
                company = "",
                name = "",
                description = "",
              } = {}) {
    this.id = id;
    this.company = company;
    this.name = name;
    this.description = description;
  }
}