import GeneralInfo from "../../../models/general";
import InputIcon from "../../global/input/InputIcon";
import {useTranslation} from "react-i18next";
import {
  BiMailSend,
  BiPhone,
  BiRename,
  BiSolidCity,
  BiSolidSchool
} from "react-icons/bi";
import {styled} from "@chakra-ui/react";
import React, {useState} from "react";

const SectionContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
    width: "90%",
    padding: "20px",
    margin: "10px",
  }
})

const InfoAddressContent = styled("div", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    minWidth: "300px",
    flexWrap: "wrap",
    width: "100%",
  }
})

interface InfoCompanyContentProps {
  general: GeneralInfo;
  setGeneral: (general: GeneralInfo) => void;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  loading: boolean;
}

function InfoCompanyContent({
                              general, loading, setGeneral, isDirty, setIsDirty
                            }: InfoCompanyContentProps) {
  const {t} = useTranslation();
  const [errorSave, setErrorSave] = useState<boolean>(false);

  const handleInputChange = (name: string, e: { target: { value: any; }; }) => {
    setGeneral({
      ...general, [name]: e.target.value,
    } as GeneralInfo);
    console.log(e)
    setIsDirty(true);
  };

  return (
    <SectionContent>
      <h1
        style={{paddingBottom: "15px", alignSelf:"center"}}>{t("pages.my_company.general.sections.company.title")}</h1>


      <InputIcon
        icon={<BiRename color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.company.name") as string}
        value={general.name || ""}
        onChange={(e) => {
          handleInputChange("name", e);
        }}
        type="text"
        width={"80%"}
      />
      <InputIcon
        icon={<BiRename color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.company.acronym") as string}
        value={general.acronym_name || ""}
        onChange={(e) => handleInputChange("acronym_name", e)}
        type="text"
        width={"40%"}
      />
      <InputIcon
        icon={<BiPhone color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.company.fax") as string}
        value={general.fax || ""}
        onChange={(e) => handleInputChange("fax", e)}
        width={"40%"}
        type="fax"
      />
      <InputIcon
        icon={<BiMailSend color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.company.mail") as string}
        value={general.email || ""}
        onChange={(e) => handleInputChange("email", e)}
        type="mail"
      />
      <InputIcon
        icon={<BiSolidSchool color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.company.school") as string}
        value={general.school || ""}
        onChange={(e) => handleInputChange("school", e)}
        type="text"
      />
      <InfoAddressContent>
        <InputIcon
          icon={<BiSolidCity color="#918D86"/>}
          placeholder={t("pages.my_company.general.sections.company.address") as string}
          value={general.address || ""}
          onChange={(e) => handleInputChange("address", e)}
          type="address"
          width={"70%"}
        />
        <InputIcon
          icon={<BiSolidCity color="#918D86"/>}
          placeholder={t("pages.my_company.general.sections.company.zip_code") as string}
          value={general.zip_code || ""}
          onChange={(e) => handleInputChange("zip_code", e)}
          type="address"
          width={"25%"}
        />
        <InputIcon
          icon={<BiSolidCity color="#918D86"/>}
          placeholder={t("pages.my_company.general.sections.company.city") as string}
          value={general.city || ""}
          onChange={(e) => handleInputChange("city", e)}
          type="address"
          width={"48%"}
        />
        <InputIcon
          icon={<BiSolidCity color="#918D86"/>}
          placeholder={t("pages.my_company.general.sections.company.country") as string}
          value={general.country || ""}
          onChange={(e) => handleInputChange("country", e)}
          type="zip_code"
          error={true}
          errorMessage={"error"}
          isInvalid={!general.country && errorSave}
          width={"48%"}
        />
      </InfoAddressContent>

    </SectionContent>);
}

export default InfoCompanyContent;
