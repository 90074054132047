import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import React from "react";
import RankInformationDrawerContent from "./RankInformationDrawerContent";
import Rank from "../../../../models/rank";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

interface RankInformationDrawerProps {
  rankId: string;
  isOpen: boolean;
  onClose: () => void;
  ranks: Rank[];
  setRanks: (ranks: Rank[]) => void;
}

function RankInformationDrawer({
                                 rankId,
                                 isOpen,
                                 onClose,
                                 ranks,
                                 setRanks,
                               }: RankInformationDrawerProps) {

  const {t} = useTranslation();

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.my_company.ranks.information.title")}
        </CustomDrawerHeader>

        <RankInformationDrawerContent rankId={rankId} onClose={onClose} ranks={ranks} setRanks={setRanks}/>

      </DrawerContent>
    </Drawer>
  )
}

export default RankInformationDrawer;