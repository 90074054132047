import React, {useEffect, useState} from "react";
import {useDisclosure} from "@chakra-ui/react";
import CompanyPageContent from "./CompanyPageContent";
import {RiGroupLine} from "react-icons/ri";
import Pole from "../../models/pole";
import PoleCard from "../../components/global/card/PoleCard";
import PoleServices from "../../services/PoleService";
import PoleCreateDrawer from "../../components/company/Pole/PoleCreateDrawer";

function CompanyPoles() {

  const [loading, setLoading] = useState<boolean>(true);
  const [poles, setPoles] = useState<Pole[]>([])

  useEffect(() => {
    setLoading(true)
    PoleServices.list().then((poles) => {
      setPoles([...poles].sort((a, b) => a.name.localeCompare(b.name)))
    }).finally(() => {
      setLoading(false)
    });
  }, [])

  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <CompanyPageContent
      name_company={"poles"}
      icon_company={<RiGroupLine color="#918D86"/>}
      loading={loading}
      onOpen={onOpen}
      drawer_company={
        <PoleCreateDrawer
          isOpen={isOpen}
          onClose={onClose}
          poles={poles}
          setPoles={setPoles}
        />}
      map_company={poles.map((pole) => <PoleCard pole={pole}
                                                 key={JSON.stringify(pole)}
                                                 poles={poles}
                                                 setPoles={setPoles}/>)}
    />

  )
}

export default CompanyPoles;