import Api from "./Api";
import Job from "../models/job";

export default class JobServices {

  static get = () => {
    return Api.get(`job/`);
  }

  static getRankById = (uuid: string) => {
    return Api.get(`job/${uuid}/`);
  }

  static list = () => {
    return Api.get(`job/all/`);
  }

  static create = (data: { name: string, description?: string }) => {
    return Api.post(`job/`, data);
  }

  static update = (data: Job , uuid: string) => {
    return Api.put(`job/${uuid}/`, data);
  }

  static delete = (uuid: string) => {
    return Api.delete(`job/${uuid}/`);
  }
}
