import Group from "../models/group";
import Api from "./Api";

export default class GroupServices {
  static get = () => {
    return Api.get(`group/`);
  };

  static list = () => {
    return Api.get(`group/all/`);
  };

  static create = (group: Group
  ) => {
    return Api.post(`group/`, {
      name: group.name,
      description: group.description,
    });
  };

  static getGroupByID = (groupID: string) => {
    return Api.get(`group/${groupID}/`);
  }

  static delete = (groupID: string) => {
    return Api.delete(`group/${groupID}/`);
  }
  static update = (
    group: Group,
    groupID: string,
  ) => {
    return Api.put(`group/${groupID}/`, group);
  }

}
