import {useTranslation} from "react-i18next";
import SearchBar from "../../components/global/input/SearchBar/SearchBar";
import PageContent from "../../components/global/views/PageContent";
import {styled} from "@chakra-ui/react";
import CustomButton from "../../components/global/button/Button";
import {HiPlus} from "react-icons/hi";
import LoadingSpinner from "../../components/global/spinner/LoadingSpinner";

const PageContentHeader = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    width: "70%",
    marginTop: "20px",
  }
})

const PageContentStyle = styled("div", {
  baseStyle: {
    marginTop: "20px",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
})

interface CompanyPageContentProps {
  name_company: string;
  icon_company: any;
  map_company: any;
  drawer_company: any;
  loading: boolean;
  onOpen?: () => void;

}

function CompanyPageContent({
                              name_company,
                              icon_company,
                              map_company,
                              drawer_company,
                              loading,
                              onOpen

                            }: CompanyPageContentProps) {

  const {t} = useTranslation();

  return (
    <PageContent>
      <PageContentHeader>
        <CustomButton icon={<HiPlus/>} onClick={onOpen}>
          {t(`pages.my_company.${name_company}.create.title`)}
        </CustomButton>
      </PageContentHeader>
      <SearchBar
        placeholder={t(`pages.my_company.${name_company}.general.search`) as string}
        icon={icon_company}
      />

      <PageContentStyle>

        {loading ? (
          <LoadingSpinner
            text={t(`pages.my_company.${name_company}.general.loading`) as string}/>
        ) : (
          <div style={{
            width: "100%",
            maxHeight: "calc(100vh - 400px)",
            minWidth: "300px",
            overflowY: "auto",
          }}>
            {map_company}
          </div>
        )}
      </PageContentStyle>
      {drawer_company}
    </PageContent>
  )
}

export default CompanyPageContent;