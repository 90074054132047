import React, {useState} from "react";
import {BiRename} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {DrawerBody, styled, useToast} from "@chakra-ui/react";
import JobImage from "../../../../assets/img/job.png";
import {RiDeleteBinLine} from "react-icons/ri";
import Pole from "../../../../models/pole";
import DrawerFooterGeneric from "../../DrawerFooterGeneric";
import InputIcon from "../../../global/input/InputIcon";
import PoleServices from "../../../../services/PoleService";

interface PoleInformationDrawerContentProps {
  poleId: string;
  onClose: () => void;
  poles: Pole[];
  setPoles: (pole: Pole[]) => void;
}

function PoleInformationDrawerContent({
                                        poleId,
                                        onClose,
                                        poles,
                                        setPoles,
                                      }: PoleInformationDrawerContentProps) {

  const {t} = useTranslation();
  const toast = useToast();
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [selectedPole, editSelectedPole] = useState<Pole>(poles.find((r) => r.id === poleId) || new Pole())

  const handleUpdatePole = (updatedPole: Pole) => {
    setLoadingSave(true)
    PoleServices.update(updatedPole, updatedPole.id)
      .then((res) => {
        setPoles(
          [...poles.filter((r) => r.id !== updatedPole.id), res]
            .sort((a, b) => a.name.localeCompare(b.name))
        )
        toast({
          title: t("pages.my_company.poles.edit.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
      })
      .catch(
        (error) => {
          toast({
            title: t("labels.error"),
            description: t(`pages.my_company.poles.edit.${error.detail}`),
            status: "error",
            duration: 4500,
          })
        }
      )
      .finally(() => {
        setLoadingSave(false)
        onClose()
      })
  }

  const handleDeletePole = (deletePoleId: Pole["id"]) => {
    setLoadingDelete(true)
    PoleServices.delete(deletePoleId)
      .then(() => {
        setPoles([...poles.filter((r) => r.id !== deletePoleId)])
        toast({
          title: t("pages.my_company.poles.delete.success"),
          status: "success",
          duration: 2000,
          isClosable: true,
        })
      })
      .catch((error) => {
        toast({
          title: t("labels.error"),
          description: t(`pages.my_company.poles.delete.${error.detail}`),
          status: "error",
          duration: 4500,
        })
      })
      .finally(() => {
        setLoadingSave(false)
        onClose()
      })
  }
  return (
    <>
      <DrawerBody>

        <img src={JobImage} alt="Add User"
             style={{height: "250px", width: "375px"}}/>

        <div style={{height: "30px"}}/>

        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.name") as string}
          value={selectedPole.name}
          onChange={(e) => editSelectedPole({...selectedPole, name: e.target.value} as Pole)}
          type="text"
        />

        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.description") as string}
          value={selectedPole.description}
          onChange={(e) => editSelectedPole({
            ...selectedPole,
            description: e.target.value
          } as Pole)}
          type="text"
        />

      </DrawerBody>
      <DrawerFooterGeneric onClose={onClose}
                           onSave={() => handleUpdatePole(selectedPole)}
                           loading={loadingSave}
                           onAction={() => handleDeletePole(selectedPole.id)}
                           labelAction={t("labels.delete") as string}
                           styleAction={{backgroundColor: "#d25353", color: "#F5F3ED"}}
                           colorAction={"#F5F3ED"}
                           loadingAction={loadingDelete}
                           actionIcon={<RiDeleteBinLine/>}/>
    </>
  )
}

export default PoleInformationDrawerContent;