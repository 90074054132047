import { Badge, Card, CardBody, Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import StudyMissionsDetails from './StudyMissionsDetails';

const missions_status = [
  {
    id: 1,
    status: 'Contact Initial',
    percentage: 10,
  },
  {
    id: 2,
    status: 'Devis envoyé',
    percentage: 20,
  },
  {
    id: 3,
    status: 'En cours',
    percentage: 50,
  },
  {
    id: 4,
    status: 'En attente de validation client',
    percentage: 60,
  },
  {
    id: 5,
    status: 'En attente de paiement',
    percentage: 80,
  },
  {
    id: 6,
    status: 'Cloturé',
    percentage: 100,
  },
  {
    id: 7,
    status: 'Avortée',
    percentage: 0,
  }
];

const missions =
{
  id: 1,
  ref: 'ETUDE_001',
  mission_number: 1,
  company: 'Deeways',
  responsible: 'Kylian Paulin',
  status: missions_status[5].percentage,
};

function MissionsDetails() {
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    navigate(`/missions/${id}`);
  }

  return (
    <Card margin={'3%'}>
      <CardBody>
        <Flex align={'baseline'}>
          <Text marginRight={'5'} fontSize={'xl'}>{missions.ref}</Text>
          <Text marginRight={'5'} fontSize={'medium'} color={'gray.400'}>{missions.mission_number}</Text>
          {missions.status < 10 ? (
            <Badge colorScheme="red">{missions_status[0].status}</Badge>
          ) : missions.status < 20 ? (
            <Badge>{missions_status[0].status}</Badge>
          ) : missions.status < 50 ? (
            <Badge colorScheme="orange">{missions_status[1].status}</Badge>
          ) : missions.status < 60 ? (
            <Badge colorScheme="yellow">{missions_status[2].status}</Badge>
          ) : missions.status < 80 ? (
            <Badge colorScheme="green">{missions_status[3].status}</Badge>
          ) : (
            <Badge colorScheme="green">{missions_status[4].status}</Badge>
          )}
        </Flex>
        <Tabs marginTop={'5'} position="relative" variant="unstyled">
          <TabList backgroundColor={'gray.100'}>
            <Tab>Etude</Tab>
            <Tab>Phase</Tab>
            <Tab>Intervenants</Tab>
            <Tab>Documents</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <StudyMissionsDetails />
            </TabPanel>
            <TabPanel>
              <p>two!</p>
            </TabPanel>
            <TabPanel>
              <p>three!</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
}

export default MissionsDetails;