import React, {useState} from "react";
import User from "../../../../../models/user";
import UserServices from "../../../../../services/UserServices";
import UserAddImage from "../../../../../assets/img/add_user.png";
import InputIcon from "../../../input/InputIcon";
import {BiRename} from "react-icons/bi";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import {BsCalendarDate} from "react-icons/bs";
import {useTranslation} from "react-i18next";
import {DrawerBody, useToast} from "@chakra-ui/react";
import DrawerFooterEditCard from "../../../../company/DrawerFooterGeneric";


interface UserInformationDrawerContentProps {
  userID: string;
  users: User[];
  setUsers: (users: User[]) => void;
  onClose: () => void;
}

function UserInformationDrawerContent({
                                        userID,
                                        onClose,
                                        users,
                                        setUsers,
                                      }: UserInformationDrawerContentProps) {

  const {t} = useTranslation();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false)
  const [newUser, setNewUser] = useState<User>(users.find((r) => r.id === userID) || new User())

  const handleUpdateUser = (updatedUser: User) => {
    if (users.find((r) => r.id === updatedUser.id) === updatedUser) {
      onClose()
      return
    }
    setLoading(true)
    UserServices.update(updatedUser, updatedUser.id).then((res) => {
      setUsers(
        [...users.filter((r) => r.id !== updatedUser.id), updatedUser]
          .sort((a, b) => a.last_name.localeCompare(b.last_name))
      )
    })
      .then(
        () => toast({
          title: t("pages.users.edit.success"),
          description: updatedUser.first_name + " " + updatedUser.last_name,
          status: "success",
          duration: 3000,
          isClosable: true,
        }))
      .catch(
        () => toast({
          title: t("pages.users.edit.error"),
          status: "error",
          duration: 3000,
          isClosable: true,
        }))
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <>
      <DrawerBody>
        <img src={UserAddImage} alt="Add User"
             style={{height: "250px", width: "300px"}}/>

        <div style={{height: "30px"}}/>

        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.lastname") as string}
          value={newUser.last_name}
          onChange={(e) => setNewUser({...newUser, last_name: e.target.value})}
          type="text"
        />

        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.firstname") as string}
          value={newUser.first_name}
          onChange={(e) => setNewUser({...newUser, first_name: e.target.value})}
          type="text"
        />

        <InputIcon
          icon={<AiOutlineMail color="#918D86"/>}
          placeholder={t("labels.mail") as string}
          value={newUser.email}
          onChange={(e) => setNewUser({...newUser, email: e.target.value})}
          type="email"
        />

        <InputIcon
          icon={<BsCalendarDate color="#918D86"/>}
          placeholder={t("labels.date_of_birth") as string}
          value={newUser.date_of_birth}
          onChange={(e) => setNewUser({...newUser, date_of_birth: e.target.value})}
          type="date"
        />

        <InputIcon
          icon={<AiOutlinePhone color="#918D86"/>}
          placeholder={t("labels.phone_number") as string}
          value={newUser.phone}
          onChange={(e) => setNewUser({...newUser, phone: e.target.value})}
          type="tel"
        />
      </DrawerBody>
      <DrawerFooterEditCard onClose={onClose}
                            onSave={() => handleUpdateUser(newUser)}
                            loading={loading}/>
    </>
  )
}

export default UserInformationDrawerContent;