import {styled} from "@chakra-ui/react";

const PageContentStyle = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20px",
    width: "60%",
  }
})

interface PageContentProps {
  children: any;
}

function PageContent({
                       children
                     }: PageContentProps) {
  return (
    <PageContentStyle>
      {children}
    </PageContentStyle>
  );
}

export default PageContent;
