import {ReactNode} from "react";
import ProtectedPage from "../router/ProtectedPage";
import User from "../models/user";
import PageTemplateMain from "./PageTemplate";

interface ProtectedPageTemplateProps {
  children: ReactNode;
}

function ProtectedPageTemplate({
  children
}: ProtectedPageTemplateProps) {

  const accessDashboard = ({user}: { user: User | undefined }) => true;

  return (
    // TODO: Fix this
    // @ts-ignore
    <ProtectedPage access={accessDashboard}>
      <PageTemplateMain>
        {children}
      </PageTemplateMain>
    </ProtectedPage>
  );
}

export default ProtectedPageTemplate;