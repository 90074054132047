
function CompanyConfig() {

  return (
    <div>
      <h1>Company Configuration</h1>
    </div>
  )
}

export default CompanyConfig;