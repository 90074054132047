import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import User from "../models/user";
import { AUTH_STATE, selectAuthState, selectUser, setAuthStatus, setUser } from "../store/features/sessionSlice";
import UserServices from "../services/UserServices";
import { isEmptyValue } from "../utils/Empty";
import { ROUTES } from "../utils/consts/router";
import LoadingPage from "../components/global/LoadingPage";

interface ProtectedPageProps {
  children: ReactNode;
  access: (options: { user: User | undefined }) => boolean;
}

function ProtectedPage({ children, access }: ProtectedPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const authState = useSelector(selectAuthState);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authState === AUTH_STATE.AUTHENTICATED && !isEmptyValue(user?.id)) {
      setLoading(false);
      return;
    }

    const token = localStorage.getItem("token");
    if (isEmptyValue(token)) {
      navigate(ROUTES.LOGIN);
      setLoading(false);
      return;
    }

    setLoading(true);

    if (authState === AUTH_STATE.NOT_AUTHENTICATED) {
      UserServices.getUser()
        .then((response) => {
          dispatch(setAuthStatus(AUTH_STATE.AUTHENTICATED));
          dispatch(setUser(response));
        })
        .catch(() => {
          localStorage.removeItem("token");
          navigate(ROUTES.LOGIN);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [authState, dispatch, navigate]);

  if (loading) return <LoadingPage />;

  if (!access({ user })) {
    return (
      <div>
        <h1>Vous n'avez pas accès à cette page</h1>
        <Button onClick={() => navigate(ROUTES.DEFAULT_SITE)}>retour accueil</Button>
      </div>
    );
  }

  return <div>{children}</div>;
}

export default ProtectedPage;
