import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled,
  useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import UserAddImage from "../../../../assets/img/add_user.png";
import User from "../../../../models/user";
import React, {useState} from "react";
import InputIcon from "../../input/InputIcon";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import {BiRename} from "react-icons/bi";
import {BsCalendarDate} from "react-icons/bs";
import DrawerFooterGeneric from "../../../company/DrawerFooterGeneric";
import UserServices from "../../../../services/UserServices";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

const CustomDrawerBody = styled(DrawerBody, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    width: "100%",
  }
})

interface UserCreateDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  users: User[];
  setUsers: (users: User[]) => void;
}

function UserCreateDrawer({
                            isOpen,
                            onClose,
                            users,
                            setUsers,
                          }: UserCreateDrawerProps) {

  const [newUser, setNewUser] = useState<User>(new User());
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const {t} = useTranslation();

  const handleCreateUser = () => {
    setLoading(true);
    UserServices.create(newUser)
      .then((user) => {
        setUsers([...users, user].sort((a, b) => a.last_name.localeCompare(b.last_name)))
        setLoading(false)
        toast({
          title: t("pages.users.create.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        toast({
          title: t("pages.users.create.error"),
          description: t(`errors.${error.message}`),
          status: "error",
          duration: 4500,
          isClosable: true,
        })
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.users.create.title")}
        </CustomDrawerHeader>

        <CustomDrawerBody>

          <img src={UserAddImage} alt="Add User"
               style={{height: "250px", width: "300px"}}/>

          <div style={{height: "30px"}}/>

          <InputIcon
            icon={<BiRename color="#918D86"/>}
            placeholder={t("labels.lastname") as string}
            value={newUser.last_name}
            onChange={(e) => setNewUser({...newUser, last_name: e.target.value})}
            type="text"
          />

          <InputIcon
            icon={<BiRename color="#918D86"/>}
            placeholder={t("labels.firstname") as string}
            value={newUser.first_name}
            onChange={(e) => setNewUser({...newUser, first_name: e.target.value})}
            type="text"
          />

          <InputIcon
            icon={<AiOutlineMail color="#918D86"/>}
            placeholder={t("labels.mail") as string}
            value={newUser.email}
            onChange={(e) => setNewUser({...newUser, email: e.target.value})}
            type="email"
          />

          <InputIcon
            icon={<BsCalendarDate color="#918D86"/>}
            placeholder={t("labels.date_of_birth") as string}
            value={newUser.date_of_birth}
            onChange={(e) => setNewUser({
              ...newUser,
              date_of_birth: e.target.value
            })}
            type="date"
          />

          <InputIcon
            icon={<AiOutlinePhone color="#918D86"/>}
            placeholder={t("labels.phone_number") as string}
            value={newUser.phone}
            onChange={(e) => setNewUser({...newUser, phone: e.target.value})}
            type="tel"
          />

        </CustomDrawerBody>
        <DrawerFooterGeneric onClose={onClose}
                             onSave={() => handleCreateUser()} loading={loading}/>
      </DrawerContent>
    </Drawer>
  )
}

export default UserCreateDrawer;