import Api from "./Api";
import Rank from "../models/rank";

export default class RankServices {

    static get = () => {
        return Api.get(`rank/`);
    }

    static getRankById = (uuid: string) => {
        return Api.get(`rank/${uuid}/`);
    }

    static list = () => {
        return Api.get(`rank/all/`);
    }

    static create = (data: { name: string, description?: string }) => {
        return Api.post(`rank/`, data);
    }

    static update = (data: Rank, uuid: string) => {
        return Api.put(`rank/${uuid}/`, data);
    }

    static delete = (uuid: string) => {
        return Api.delete(`rank/${uuid}/`);
    }
}
