import { Badge, Card, CardBody, Divider, Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function StudyMissionsDetails() {
  const navigate = useNavigate();
  return (
    <Flex>
      <Card margin={'1%'} width={'25%'}>
        <CardBody>
          <p>Numéro d'étude :</p>
          <p>Provenance :</p>
          <p>Commanditaire :</p>
          <p>Chef de Projet :</p>
          <p>Responsable Qualité</p>
        </CardBody>
      </Card>
      <Card margin={'1%'} width={'25%'}>
        <CardBody>
          <p>Chiffré :</p>
          <p>Facturé :</p>
          <p>Rétribué :</p>
          <p>Marge :</p>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default StudyMissionsDetails;