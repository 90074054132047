import React, {useState} from "react";
import {BiRename} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {DrawerBody, styled, useToast} from "@chakra-ui/react";
import JobImage from "../../../../assets/img/job.png";
import {RiDeleteBinLine} from "react-icons/ri";
import DrawerFooterGeneric from "../../DrawerFooterGeneric";
import InputIcon from "../../../global/input/InputIcon";
import Job from "../../../../models/job";
import JobServices from "../../../../services/JobService";

interface JobInformationDrawerContentProps {
  jobId: string;
  onClose: () => void;
  jobs: Job[];
  setJobs: (job: Job[]) => void;
}

function JobInformationDrawerContent({
                                        jobId,
                                        onClose,
                                        jobs,
                                        setJobs,
                                      }: JobInformationDrawerContentProps) {

  const {t} = useTranslation();
  const toast = useToast();
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [selectedJob, editSelectedJob] = useState<Job>(jobs.find((r) => r.id === jobId) || new Job())

  const handleUpdateJob = (updatedJob: Job) => {
    setLoadingSave(true)
    JobServices.update(updatedJob, updatedJob.id)
      .then((res) => {
        setJobs(
          [...jobs.filter((r) => r.id !== updatedJob.id), res]
            .sort((a, b) => a.name.localeCompare(b.name))
        )
        toast({
          title: t("pages.my_company.jobs.edit.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
      })
      .catch(
        (error) => {
          toast({
            title: t("labels.error"),
            description: t(`pages.my_company.jobs.edit.${error.detail}`),
            status: "error",
            duration: 4500,
          })
        }
      )
      .finally(() => {
        setLoadingSave(false)
        onClose()
      })
  }

  const handleDeleteJob = (deleteJobId: Job["id"]) => {
    setLoadingDelete(true)
    JobServices.delete(deleteJobId)
      .then(() => {
        setJobs([...jobs.filter((r) => r.id !== deleteJobId)])
        toast({
          title: t("pages.my_company.jobs.delete.success"),
          status: "success",
          duration: 2000,
          isClosable: true,
        })
      })
      .catch((error) => {
        toast({
          title: t("labels.error"),
          description: t(`pages.my_company.jobs.delete.${error.detail}`),
          status: "error",
          duration: 4500,
        })
      })
      .finally(() => {
        setLoadingSave(false)
        onClose()
      })
  }
  return (
    <>
      <DrawerBody>

        <img src={JobImage} alt="Add User"
             style={{height: "250px", width: "375px"}}/>

        <div style={{height: "30px"}}/>

        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.name") as string}
          value={selectedJob.name}
          onChange={(e) => editSelectedJob({...selectedJob, name: e.target.value} as Job)}
          type="text"
        />

        <InputIcon
          icon={<BiRename color="#918D86"/>}
          placeholder={t("labels.description") as string}
          value={selectedJob.description}
          onChange={(e) => editSelectedJob({
            ...selectedJob,
            description: e.target.value
          } as Job)}
          type="text"
        />

      </DrawerBody>
      <DrawerFooterGeneric onClose={onClose}
                           onSave={() => handleUpdateJob(selectedJob)}
                           loading={loadingSave}
                           onAction={() => handleDeleteJob(selectedJob.id)}
                           labelAction={t("labels.delete") as string}
                           styleAction={{backgroundColor: "#d25353", color: "#F5F3ED"}}
                           colorAction={"#F5F3ED"}
                           loadingAction={loadingDelete}
                           actionIcon={<RiDeleteBinLine/>}/>
    </>
  )
}

export default JobInformationDrawerContent;