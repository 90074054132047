import Rank from "../../models/rank";
import React, {useEffect, useState} from "react";
import RankServices from "../../services/RankServices";
import {useDisclosure} from "@chakra-ui/react";
import RankCreateDrawer from "../../components/company/Rank/RankCreateDrawer";
import CompanyPageContent from "./CompanyPageContent";
import RankCard from "../../components/global/card/RankCard";
import {RiGroupLine} from "react-icons/ri";

function CompanyRanks() {

  const [loading, setLoading] = useState<boolean>(true);
  const [ranks, setRanks] = useState<Rank[]>([])

  useEffect(() => {
    setLoading(true)
    RankServices.list().then((ranks) => {
      setRanks([...ranks].sort((a, b) => a.name.localeCompare(b.name)))
    }).finally(() => {
      setLoading(false)
    });
  }, [])

  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <CompanyPageContent
      title={"ranks"}
      icon_company={<RiGroupLine color="#918D86"/>}
      loading={loading}
      onOpen={onOpen}
      drawer_company={
        <RankCreateDrawer
          isOpen={isOpen}
          onClose={onClose}
          ranks={ranks}
          setRanks={setRanks}
        />}
      map_company={ranks.map((rank) => <RankCard rank={rank}
                                                 key={JSON.stringify(rank)}
                                                 ranks={ranks}
                                                 setRanks={setRanks}/>)}
      name_company={"ranks"}
    />

  )
}
export default CompanyRanks;