import { Badge, Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';

const missions_status = [
  {
    id: 1,
    status: 'Contact Initial',
    percentage: 10,
  },
  {
    id: 2,
    status: 'Devis envoyé',
    percentage: 20,
  },
  {
    id: 3,
    status: 'En cours',
    percentage: 50,
  },
  {
    id: 4,
    status: 'En attente de validation client',
    percentage: 60,
  },
  {
    id: 5,
    status: 'En attente de paiement',
    percentage: 80,
  },
  {
    id: 6,
    status: 'Cloturé',
    percentage: 100,
  },
  {
    id: 7,
    status: 'Avortée',
    percentage: 0,
  }
];

const missions = [
  {
    id: 1,
    ref: 'ETUDE_001',
    company: 'Deeways',
    responsible: 'Kylian Paulin',
    status: missions_status[0].percentage,
  },
  {
    id: 2,
    ref: 'ETUDE_002',
    company: 'Tolnkee',
    responsible: 'Clément Loeillet',
    status: missions_status[5].percentage,
  },
];

function MissionsLanding() {
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    navigate(`/missions/${id}`);
  }

  return (
    <Box overflowX="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Ref</Th>
            <Th>Entreprise</Th>
            <Th>Responsable</Th>
            <Th>Avancement</Th>
          </Tr>
        </Thead>
        <Tbody>
          {missions.map((mission) => (
            <Tr onClick={() => { handleClick(mission.id) }} key={mission.id} bg="white" _hover={{ bg: "gray.200" }} cursor={"pointer"}>
              <Td>{mission.ref}</Td>
              <Td>{mission.company}</Td>
              <Td>{mission.responsible}</Td>
              {mission.status < 10 ? (
                <Td>
                  <Badge colorScheme="red">{missions_status[0].status}</Badge>
                </Td>
              ) :
                mission.status < 20 ? (
                  <Td>
                    <Badge>{missions_status[0].status}</Badge>
                  </Td>
                ) : mission.status < 50 ? (
                  <Td>
                    <Badge colorScheme="orange">{missions_status[1].status}</Badge>
                  </Td>
                ) : mission.status < 60 ? (
                  <Td>
                    <Badge colorScheme="yellow">{missions_status[2].status}</Badge>
                  </Td>
                ) : mission.status < 80 ? (
                  <Td>
                    <Badge colorScheme="green">{missions_status[3].status}</Badge>
                  </Td>
                ) : (
                  <Td>
                    <Badge colorScheme="green">{missions_status[4].status}</Badge>
                  </Td>
                )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

export default MissionsLanding;