import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import React from "react";
import UserInformationDrawerContent from "./UserInformationDrawerContent";
import User from "../../../../../models/user";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

interface UserInformationDrawerProps {
  userID: string;
  isOpen: boolean;
  onClose: () => void;
  users: User[];
  setUsers: (users: User[]) => void;
}

function UserInformationDrawer({
                                 userID,
                                 isOpen,
                                 onClose,
                                 users,
                                 setUsers,
                               }: UserInformationDrawerProps) {

  const {t} = useTranslation();

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.users.information.title")}
        </CustomDrawerHeader>

        <UserInformationDrawerContent userID={userID} onClose={onClose}
                                      users={users} setUsers={setUsers}/>

      </DrawerContent>
    </Drawer>
  )
}

export default UserInformationDrawer;