import React, {useEffect, useState} from "react";
import {useDisclosure} from "@chakra-ui/react";
import CompanyPageContent from "./CompanyPageContent";
import {RiGroupLine} from "react-icons/ri";
import Job from "../../models/job";
import JobCard from "../../components/global/card/JobCard";
import JobServices from "../../services/JobService";
import JobCreateDrawer from "../../components/company/Job/JobCreateDrawer";

function CompanyJobs() {

  const [loading, setLoading] = useState<boolean>(true);
  const [jobs, setJobs] = useState<Job[]>([])

  useEffect(() => {
    setLoading(true)
    JobServices.list().then((jobs) => {
      setJobs([...jobs].sort((a, b) => a.name.localeCompare(b.name)))
    }).finally(() => {
      setLoading(false)
    });
  }, [])

  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <CompanyPageContent
      name_company={"jobs"}
      icon_company={<RiGroupLine color="#918D86"/>}
      loading={loading}
      onOpen={onOpen}
      drawer_company={
        <JobCreateDrawer
          isOpen={isOpen}
          onClose={onClose}
          jobs={jobs}
          setJobs={setJobs}
        />}
      map_company={jobs.map((job) => <JobCard job={job}
                                              key={JSON.stringify(job)}
                                              jobs={jobs}
                                              setJobs={setJobs}/>)}
    />

  )
}

export default CompanyJobs;