export const ROUTES = {
  HOME: '/*',
  HOME_CHILD: '/',
  MISSIONS: '/missions/*',
  MISSIONS_CHILD: '/missions',
  MISSIONS_DETAIL: '/missions/:id/*',
  MISSIONS_DETAIL_CHILD: '/missions/:id',
  MISSIONS_CREATE: '/missions/create',
  CUSTOMERS: '/customers/*',
  CUSTOMERS_CHILD: '/customers',
  CUSTOMERS_DETAIL: '/customers/:id/*',
  CUSTOMERS_DETAIL_CHILD: '/customers/:id',
  CUSTOMERS_CREATE: '/customers/create',
  USER: '/user/*',
  USERS: '/users/*',
  USERS_CHILD: '/users',
  USERS_DETAIL: '/users/:id/*',
  USERS_DETAIL_CHILD: '/users/:id',
  USERS_CREATE: '/users/create',
  COMPANY: '/company/*',
  COMPANY_CHILD: '/company',
  COMPANY_GENERAL: '/company',
  COMPANY_CUSTOM: '/company/custom',
  COMPANY_CUSTOM_CHILD: '/custom',
  COMPANY_ADMIN: '/company/admin',
  COMPANY_ADMIN_CHILD: '/admin',
  COMPANY_GROUPS: '/company/groups',
  COMPANY_GROUPS_CHILD: '/groups',
  COMPANY_RANKS: '/company/ranks',
  COMPANY_RANKS_CHILD: '/ranks',
  COMPANY_POLES: '/company/poles',
  COMPANY_POLES_CHILD: '/poles',
  COMPANY_JOBS: '/company/jobs',
  COMPANY_JOBS_CHILD: '/jobs',
  PROFILE: '/profile',
  ERROR_404: "*/*",
  LOGIN: '/login',
  DEFAULT_SITE: "https://juniortaker.com"
}
