import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled,
  useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import InputIcon from "../../global/input/InputIcon";
import {BiRename} from "react-icons/bi";
import DrawerFooterGeneric from "../DrawerFooterGeneric";
import Group from "../../../models/group";
import GroupServices from "../../../services/GroupService";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

const CustomDrawerBody = styled(DrawerBody, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    width: "100%",
  }
})

interface RankCreateDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  groups: Group[];
  setGroups: (groups: Group[]) => void;
}

function RankCreateDrawer({
                            isOpen,
                            onClose,
                            groups,
                            setGroups,
                          }: RankCreateDrawerProps) {

  const {t} = useTranslation();
  const toast = useToast();

  const [newGroup, setNewGroup] = useState<Group>(new Group());
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setNewGroup(new Group())
  }, [isOpen]);

  const handleCreateGroup = () => {
    if (!newGroup.name) {
      toast({
        title: t("labels.error"),
        description: t("pages.my_company.groups.create.error_empty_name"),
        status: "error",
        duration: 4500,
      })
      return;
    }
    setLoading(true)
    GroupServices.create(newGroup)
      .then((group) => {
        setGroups([...groups, group].sort((a, b) => a.name.localeCompare(b.name)))
        toast({
          title: t("pages.my_company.groups.create.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
        onClose();
      })
      .catch((error) => {
        toast({
          title: t("labels.error"),
          description: t(`pages.my_company.groups.create.${error.detail}`),
          status: "error",
          duration: 4500,
        })
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.my_company.groups.create.title")}
        </CustomDrawerHeader>

        <CustomDrawerBody paddingTop={"30px"}>

          <InputIcon
            icon={<BiRename color="#918D86"/>}
            placeholder={t("labels.name") as string}
            value={newGroup.name as string}
            onChange={(e) => setNewGroup({
              ...newGroup,
              name: e.target.value
            } as Group)}
            type="text"
          />

          <InputIcon
            icon={<BiRename color="#918D86"/>}
            placeholder={t("labels.description") as string}
            value={newGroup.description as string}
            onChange={(e) => setNewGroup({
              ...newGroup,
              description: e.target.value
            } as Group)}
            type="textArea"
          />

        </CustomDrawerBody>

        <DrawerFooterGeneric onClose={onClose}
                             onSave={() => handleCreateGroup()}
                             loading={loading}/>

      </DrawerContent>
    </Drawer>
  )
}

export default RankCreateDrawer;