import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import React from "react";
import Job from "../../../../models/job";
import JobInformationDrawerContent from "./JobInformationDrawerContent";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

interface JobInformationDrawerProps {
  jobId: string;
  isOpen: boolean;
  onClose: () => void;
  jobs: Job[];
  setJobs: (jobs: Job[]) => void;
}

function JobInformationDrawer({
                                 jobId,
                                 isOpen,
                                 onClose,
                                 jobs,
                                 setJobs,
                               }: JobInformationDrawerProps) {

  const {t} = useTranslation();

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.my_company.jobs.information.title")}
        </CustomDrawerHeader>

        <JobInformationDrawerContent jobId={jobId} onClose={onClose} jobs={jobs} setJobs={setJobs}/>

      </DrawerContent>
    </Drawer>
  )
}

export default JobInformationDrawer;