import SimpleCard from "./SimpleCard";
import {useDisclosure} from "@chakra-ui/react";
import Job from "../../../models/job";
import JobInformationDrawer from "../../company/Job/JobInformatinDrawer/JobInformationDrawer";

interface GroupCardProps {
  job: Job;
  jobs: Job[];
  setJobs: (groups: Job[]) => void;
}

function JobCard({
                    job,
                    jobs,
                    setJobs,
                  }: GroupCardProps) {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <SimpleCard
        name={job.name}
        title={job.name}
        subtitle={job.description}
        onOpen={() => onOpen()}
      />

      <JobInformationDrawer jobId={job.id} isOpen={isOpen} onClose={onClose} jobs={jobs} setJobs={setJobs}/>
    </>
  )
}

export default JobCard;