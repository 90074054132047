import {Input, styled, Tooltip, useToast} from "@chakra-ui/react";
import {isEmptyValue} from "../utils/Empty";
import AuthServices from "../services/AuthServices";
import UserServices from "../services/UserServices";
import {AUTH_STATE, setAuthStatus, setUser} from "../store/features/sessionSlice";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {FiArrowLeft} from "react-icons/fi";
import Logo from "../assets/img/logo.png";
import Taker from "../assets/img/taker.jpeg";
import GoogleLogo from "../assets/img/google.png";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../utils/consts/router";

const Page = styled("div", {
  baseStyle: {
    display: "flex",
    backgroundColor: "#F6F5EF",
    width: "100%",
    height: "100vh",
  }
})

const LeftContainer = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "500px",
    backgroundColor: "white",
  }
})

const RightContainer = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  }
})

const LogoContainer = styled("div", {
  baseStyle: {
    width: "415px",
    display: "flex",
    marginTop: "2rem",
  }
})

const BackSection = styled("div", {
  baseStyle: {
    width: "400px",
    display: "flex",
    marginTop: "90px",
  }
})

const CustomButton = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    paddingRight: "15px",
    paddingLeft: "15px",
    backgroundColor: "#F6F5EF",
    borderRadius: 25,
    cursor: "pointer",
    shadow: "1px 2px 2px rgba(0, 0, 0, 0.05)",
    ":hover": {
      backgroundColor: "#F6F5F0",
      transition: "all 0.2s ease-in-out",
    }
  }
})

const ButtonText = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "regular",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
    marginTop: "3px",
    marginLeft: "5px",
  }
})

const ContentContainer = styled("div", {
  baseStyle: {
    padding: "30px",
  }
})

const Title = styled("div", {
  baseStyle: {
    width: "400px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#2D2D2D",
    marginTop: "40px",
    fontFamily: "Lora",
    display: "flex",
  }
})

const Description = styled("div", {
  baseStyle: {
    width: "400px",
    fontSize: "15px",
    fontWeight: "regular",
    color: "#2D2D2D",
    marginTop: "10px",
    fontFamily: "Mabry Pro",
  }
})

const AuthSection = styled("div", {
  baseStyle: {
    width: "400px",
    marginTop: "30px",
    cursor: "pointer",
  }
})

const AuthButton = styled("div", {
  baseStyle: {
    display: "flex",
    backgroundColor: "#4F86EC",
    width: "250px",
    height: "50px",
    borderRadius: 3,
    shadow: "1px 2px 2px rgba(0, 0, 0, 0.25)",
  }
})

const AuthButtonLogo = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "3px",
    marginLeft: "3px",
    width: "44px",
    height: "44px",
    borderRadius: 3,
    backgroundColor: "#FFFFFF",
  }
})

const AuthButtonText = styled("div", {
  baseStyle: {
    width: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Mabry Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#FFFFFF",
  }
})

const FooterSection = styled("div", {
  baseStyle: {
    width: "400px",
    marginBottom: "20px",
    fontSize: "12px",
  }
})

const ErrorText = styled("div", {
  baseStyle: {
    width: "400px",
    fontSize: "12px",
    fontWeight: "regular",
    color: "#FF0000",
    marginTop: "5px",
  }
})

const SpacerGrow = styled("div", {
  baseStyle: {
    flexGrow: 1,
  }
})

const InputSection = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  }
})

const CustomInput = styled(Input, {
  baseStyle: {
    marginTop: "10px",
    marginBottom: "10px",
    borderColor: "#F0EFE5",
    backgroundColor: "#ffffff",
    maxWidth: "400px",
    fontFamily: "Mabry Pro",
    ":hover": {
      backgroundColor: "#F6F5F0",
      borderColor: "#F0EFE5",
    },
    ":focus": {
      backgroundColor: "#F6F5F0",
      borderColor: "#232323",
      borderWidth: "0.5px",
    }
  }
})

const ButtonSection = styled("div", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  }
})

const ContentImage = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  }
})

const ContentTitle = styled("div", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    width: "60%",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#2D2D2D",
    marginTop: "15px",
    fontFamily: "Lora",
  }
})

const ContentDescription = styled("div", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    width: "60%",
    fontSize: "15px",
    fontWeight: "regular",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

function Login() {

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [email, setMail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);

  const errorMailMessage = t("pages.login.error.mail_not_fill");
  const errorPasswordMessage = t("pages.login.error.password_not_fill");

  const handleLogin = () => {

    if (isEmptyValue(email)) {
      setErrorEmail(true)
      return;
    }

    if (isEmptyValue(password)) {
      setErrorPassword(true);
      return;
    }

    AuthServices.signIn(email, password)
      .then((response) => {
        localStorage.setItem("token", response.token);
        UserServices.getUser().then((response) => dispatch(setUser(response)))
        dispatch(setAuthStatus(AUTH_STATE.AUTHENTICATED));
        navigate(ROUTES.HOME_CHILD)
        return;
      })
      .catch((error) => {
        dispatch(setAuthStatus(AUTH_STATE.ERROR))
        toast({
          title: t(`pages.login.error.${error.message.toLowerCase()}`),
          status: "error",
          duration: 5000,
        });
      })
  }

  const handleBack = () => {
    dispatch(setAuthStatus(AUTH_STATE.NOT_AUTHENTICATED));
    window.location.href = ROUTES.DEFAULT_SITE;
  }

  return (
    <Page>
      <LeftContainer>
        <LogoContainer>
          <img alt="Logo" src={Logo} width={100}/>
        </LogoContainer>
        <ContentContainer>
          <BackSection>
            <CustomButton onClick={() => handleBack()}>
              <FiArrowLeft/>
              <ButtonText>{t("labels.back")}</ButtonText>
            </CustomButton>
          </BackSection>
          <Title>{t("pages.login.title")}</Title>
          <Description>{t("pages.login.description")}</Description>
          <InputSection>
            <CustomInput
              placeholder={t("labels.mail")}
              value={email}
              onChange={(e: any) => {
                setMail(e.target.value);
                setErrorEmail(false);
              }}
              sx={{
                borderColor: errorEmail ? "#FF0000" : "#F0EFE5",
              }}
            />
            {errorEmail && <ErrorText>{errorMailMessage}</ErrorText>}
            <CustomInput
              placeholder={t("labels.password")}
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value)
                setErrorPassword(false);
              }}
              type="password"
              sx={{
                borderColor: errorPassword ? "#FF0000" : "#F0EFE5",
              }}
            />
            {errorPassword && <ErrorText>{errorPasswordMessage}</ErrorText>}
            <ButtonSection>
              <CustomButton onClick={handleLogin}>
                <ButtonText
                  sx={{
                    textColor: email.length > 0 && password.length > 0 ? "#2D2D2D" : "#B6B4AB",
                    ":hover": {
                      textColor: email.length > 0 && password.length > 0 ? "#2D2D2D" : "#B6B4AB",
                    }
                  }}
                >
                  {t("labels.login")}
                </ButtonText>
              </CustomButton>
            </ButtonSection>
          </InputSection>
          <AuthSection>
            <Tooltip label={t("pages.login.google_soon")}>
              <AuthButton>
                <AuthButtonLogo>
                  <img alt="Logo" src={GoogleLogo} width={23}/>
                </AuthButtonLogo>
                <AuthButtonText>{t("pages.login.google")}</AuthButtonText>
              </AuthButton>
            </Tooltip>
          </AuthSection>
        </ContentContainer>
        <SpacerGrow/>
        <FooterSection>
          {t("pages.login.footer")}
        </FooterSection>
      </LeftContainer>
      <RightContainer>
        <ContentImage>
          <img alt="Taker" src={Taker} width="60%" style={{borderRadius: 20}}/>
        </ContentImage>
        <ContentTitle>{t("pages.login.rightTitle")}</ContentTitle>
        <ContentDescription>{t("pages.login.rightDescription")}</ContentDescription>
      </RightContainer>
    </Page>
  );
}

export default Login;