export default class GeneralInfo {
  id: string;
  name: string;
  acronym_name: string;
  type_company: string;
  school: string;
  juridical_status: string;
  siret: string;
  naf_code: string;
  urssaf_number: string;
  tva_number: string;
  judicial_court: string;
  address: string;
  city: string;
  zip_code: string;
  country: string;
  phone: string;
  fax: string;
  email: string;
  website: string;
  award_period: string;
  created_at: string;
  updated_at: string;

  constructor({
                id = "",
                name = "",
                address = "",
                city = "",
                country = "",
                phone = "",
                school = "",
                acronyme_name = "",
                type_company = "",
                juridical_status = "",
                siret = "",
                naf_code = "",
                urssaf_number = "",
                tva_number = "",
                judicial_court = "",
                zip_code = "",
                fax = "",
                email = "",
                website = "",
                award_period = "",
                created_at = "",
                updated_at = "",
              } = {}) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.city = city;
    this.country = country;
    this.phone = phone;
    this.school = school;
    this.acronym_name = acronyme_name;
    this.type_company = type_company;
    this.juridical_status = juridical_status;
    this.siret = siret;
    this.naf_code = naf_code;
    this.urssaf_number = urssaf_number;
    this.tva_number = tva_number;
    this.judicial_court = judicial_court;
    this.zip_code = zip_code;
    this.fax = fax;
    this.email = email;
    this.website = website;
    this.award_period = award_period;
    this.created_at = created_at;
    this.updated_at = updated_at;

  }
}