import Group from "../models/group";
import Api from "./Api";
import GeneralInfo from "../models/general";

export default class GeneralServices {
  static get = () => {
    return Api.get(`company/`);
  };

  static update = (data: GeneralInfo) => {
    return Api.put(`company/`, data);
  };

}
