import SimpleCard from "./SimpleCard";
import {useDisclosure} from "@chakra-ui/react";
import Pole from "../../../models/pole";
import PoleInformationDrawer from "../../company/Pole/PoleInformatinDrawer/PoleInformationDrawer";

interface GroupCardProps {
  pole: Pole;
  poles: Pole[];
  setPoles: (groups: Pole[]) => void;
}

function PoleCard({
                     pole,
                     poles,
                     setPoles,
                   }: GroupCardProps) {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <SimpleCard
        name={pole.name}
        title={pole.name}
        subtitle={pole.description}
        onOpen={() => onOpen()}
      />

      <PoleInformationDrawer poleId={pole.id} isOpen={isOpen} onClose={onClose} poles={poles} setPoles={setPoles}/>
    </>
  )
}

export default PoleCard;