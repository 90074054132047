
function CompanyCustom() {

  return (
    <div>
      <h1>Company Custom</h1>
    </div>
  )
}

export default CompanyCustom;