import Rank from "../../../models/rank";
import SimpleCard from "./SimpleCard";
import RankInformationDrawer from "../../company/Rank/RankInformationDrawer/RankInformationDrawer";
import {useDisclosure} from "@chakra-ui/react";

interface RankCardProps {
  rank: Rank;
  ranks: Rank[];
  setRanks: (ranks: Rank[]) => void;
}

function RankCard({
                    rank,
                    ranks,
                    setRanks,
                  }: RankCardProps) {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <SimpleCard
        name={rank.name}
        title={rank.name}
        subtitle={rank.description}
        onOpen={() => onOpen()}
      />

      <RankInformationDrawer rankId={rank.id} isOpen={isOpen} onClose={onClose} ranks={ranks} setRanks={setRanks}/>
    </>
  )
}

export default RankCard;