import {CircularProgress} from '@chakra-ui/react'

function LoadingPage() {

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}>
      <CircularProgress isIndeterminate color='blue.900'/>
    </div>
  );
}

export default LoadingPage;