import React, {useEffect, useState} from "react";
import {useDisclosure} from "@chakra-ui/react";
import CompanyPageContent from "./CompanyPageContent";
import {RiGroupLine} from "react-icons/ri";
import Group from "../../models/group";
import GroupServices from "../../services/GroupService";
import GroupCreateDrawer from "../../components/company/Group/GroupCreateDrawer";
import GroupCard from "../../components/global/card/GroupCard";

function CompanyGroups() {

  const [loading, setLoading] = useState<boolean>(true);
  const [groups, setGroups] = useState<Group[]>([])

  useEffect(() => {
    setLoading(true)
    GroupServices.list().then((groups) => {
      setGroups([...groups].sort((a, b) => a.name.localeCompare(b.name)))
    }).finally(() => {
      setLoading(false)
    });
  }, [])

  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <CompanyPageContent
      name_company={"groups"}
      icon_company={<RiGroupLine color="#918D86"/>}
      loading={loading}
      onOpen={onOpen}
      drawer_company={
        <GroupCreateDrawer
          isOpen={isOpen}
          onClose={onClose}
          groups={groups}
          setGroups={setGroups}
        />}
      map_company={groups.map((group) => <GroupCard group={group}
                                                    key={JSON.stringify(group)}
                                                    groups={groups}
                                                    setGroups={setGroups}/>)}
    />

  )
}

export default CompanyGroups;