import Api from "./Api";
import Pole from "../models/pole";

export default class PoleServices {

  static get = () => {
    return Api.get(`pole/`);
  }

  static getRankById = (uuid: string) => {
    return Api.get(`pole/${uuid}/`);
  }

  static list = () => {
    return Api.get(`pole/all/`);
  }

  static create = (data: { name: string, description?: string }) => {
    return Api.post(`pole/`, data);
  }

  static update = (data: Pole , uuid: string) => {
    return Api.put(`pole/${uuid}/`, data);
  }

  static delete = (uuid: string) => {
    return Api.delete(`pole/${uuid}/`);
  }
}
