import GeneralInfo from "../../../models/general";
import InputIcon from "../../global/input/InputIcon";
import {useTranslation} from "react-i18next";
import {styled} from "@chakra-ui/react";
import {AiOutlineFieldNumber, AiOutlineIdcard} from "react-icons/ai";

const SectionContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "300px",
    width: "90%",
    padding: "20px",
    margin: "10px",
  }
})

interface InfoJuridicContentProps {
  general: GeneralInfo;
  setGeneral: (general: GeneralInfo) => void;
  loading: boolean;
}

function InfoJuridicContent({
                              general,
                              loading,
                              setGeneral
                            }: InfoJuridicContentProps) {
  const {t} = useTranslation();
  return (
    <SectionContent>
      <h1
        style={{paddingBottom: "15px"}}>{t("pages.my_company.general.sections.juridic.title")}</h1>
      <InputIcon
        icon={<AiOutlineIdcard color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.juridic.status") as string}
        value={general.juridical_status || ""}
        onChange={(e) => setGeneral({
          ...general,
          juridical_status: e.target.value
        } as GeneralInfo)}
        type="text"
      />
      <InputIcon
        icon={<AiOutlineFieldNumber color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.juridic.tva") as string}
        value={general.tva_number || ""}
        onChange={(e) => setGeneral({
          ...general,
          tva_number: e.target.value
        } as GeneralInfo)}
        type="text"
      />
      <InputIcon
        icon={<AiOutlineFieldNumber color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.juridic.urssaf") as string}
        value={general.urssaf_number || ""}
        onChange={(e) => setGeneral({
          ...general,
          urssaf_number: e.target.value
        } as GeneralInfo)}
        type="text"
      />
      <InputIcon
        icon={<AiOutlineFieldNumber color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.juridic.naf") as string}
        value={general.naf_code || ""}
        onChange={(e) => setGeneral({
          ...general,
          naf_code: e.target.value
        } as GeneralInfo)}
        type="text"
      />
      <InputIcon
        icon={<AiOutlineFieldNumber color="#918D86"/>}
        placeholder={t("pages.my_company.general.sections.juridic.siret") as string}
        value={general.siret || ""}
        onChange={(e) => setGeneral({
          ...general,
          siret: e.target.value
        } as GeneralInfo)}
        type="text"
      />

    </SectionContent>
  );
}

export default InfoJuridicContent;
