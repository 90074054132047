import {Spinner} from "@chakra-ui/react";
import Typography from "../Typography";

interface LoadingSpinnerProps {
    text?: string;
}

function LoadingSpinner({
                            text
                        }: LoadingSpinnerProps) {

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Spinner
                thickness='1px'
                speed='0.5s'
                emptyColor='#F5F3ED'
                color='#2D2D2D'
                size='xs'
                marginRight={"10px"}
            />
            <Typography variant="body">{text}</Typography>
        </div>
    )
}

export default LoadingSpinner;