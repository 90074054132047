export default class Job {
  id: string;
  name: string;
  description: string;
  color: string;
  pole: string;
  company: string;

  constructor({
                id = "",
                name = "",
                description = "",
                color = "",
                pole = "",
                company = "",
              } = {}) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.color = color;
    this.pole = pole;
    this.company = company;
  }
}