import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import React from "react";
import Pole from "../../../../models/pole";
import PoleInformationDrawerContent from "./PoleInformationDrawerContent";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

interface PoleInformationDrawerProps {
  poleId: string;
  isOpen: boolean;
  onClose: () => void;
  poles: Pole[];
  setPoles: (poles: Pole[]) => void;
}

function PoleInformationDrawer({
                                 poleId,
                                 isOpen,
                                 onClose,
                                 poles,
                                 setPoles,
                               }: PoleInformationDrawerProps) {

  const {t} = useTranslation();

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.my_company.poles.information.title")}
        </CustomDrawerHeader>

        <PoleInformationDrawerContent poleId={poleId} onClose={onClose} poles={poles} setPoles={setPoles}/>

      </DrawerContent>
    </Drawer>
  )
}

export default PoleInformationDrawer;