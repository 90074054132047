import {Route, Routes} from "react-router-dom";
import UsersLanding from "./UsersLanding";

function Users() {

  return (
    <Routes>
      <Route path="/" element={<UsersLanding/>}/>
    </Routes>
  )
}

export default Users;