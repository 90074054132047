import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import React from "react";
import GroupInformationDrawerContent from "./GroupInformationDrawerContent";
import Group from "../../../../models/group";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

interface RankInformationDrawerProps {
  groupId: string;
  isOpen: boolean;
  onClose: () => void;
  groups: Group[];
  setGroups: (groups: Group[]) => void;
}

function GroupInformationDrawer({
                                  groupId,
                                  isOpen,
                                  onClose,
                                  groups,
                                  setGroups,
                                }: RankInformationDrawerProps) {

  const {t} = useTranslation();

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.my_company.groups.information.title")}
        </CustomDrawerHeader>

        <GroupInformationDrawerContent groupId={groupId} onClose={onClose}
                                       groups={groups} setGroups={setGroups}/>

      </DrawerContent>
    </Drawer>
  )
}

export default GroupInformationDrawer;