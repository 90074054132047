import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled,
  useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import InputIcon from "../../global/input/InputIcon";
import {BiRename} from "react-icons/bi";
import DrawerFooterGeneric from "../DrawerFooterGeneric";
import Pole from "../../../models/pole";
import RankServices from "../../../services/RankServices";
import PoleServices from "../../../services/PoleService";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

const CustomDrawerBody = styled(DrawerBody, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    width: "100%",
  }
})

interface PoleCreateDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  poles: Pole[];
  setPoles: (poles: Pole[]) => void;
}

function PoleCreateDrawer({
                            isOpen,
                            onClose,
                            poles,
                            setPoles,
                          }: PoleCreateDrawerProps) {

  const {t} = useTranslation();
  const toast = useToast();

  const [newPole, setNewPole] = useState<Pole>(new Pole());
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setNewPole(new Pole())
  }, [isOpen]);

  const handleCreatePole = () => {
    if (!newPole.name) {
      toast({
        title: t("labels.error"),
        description: t("pages.my_company.poles.create.error_empty_name"),
        status: "error",
        duration: 4500,
      })
      return;
    }
    setLoading(true)
    PoleServices.create(newPole)
      .then((pole) => {
        setPoles([...poles, pole].sort((a, b) => a.name.localeCompare(b.name)))
        setLoading(false)
        toast({
          title: t("pages.my_company.poles.create.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
        onClose()
      })
      .catch(
        (error) => {
          toast({
            title: t("labels.error"),
            description: t(`pages.my_company.poles.create.${error.detail}`),
            status: "error",
            duration: 4500,
          })
        }
      ).finally(() => {
      setLoading(false)
    });
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.my_company.poles.create.title")}
        </CustomDrawerHeader>

        <CustomDrawerBody paddingTop={"30px"}>

          <InputIcon
            icon={<BiRename color="#918D86"/>}
            placeholder={t("labels.name") as string}
            value={newPole.name as string}
            onChange={(e) => setNewPole({...newPole, name: e.target.value} as Pole)}
            type="text"
          />

          <InputIcon
            icon={<BiRename color="#918D86"/>}
            placeholder={t("labels.description") as string}
            value={newPole.description as string}
            onChange={(e) => setNewPole({...newPole, description: e.target.value} as Pole)}
            type="textArea"
          />

        </CustomDrawerBody>

        <DrawerFooterGeneric onClose={onClose}
                             onSave={() => handleCreatePole()}
                             loading={loading}/>


      </DrawerContent>
    </Drawer>
  )
}

export default PoleCreateDrawer;