export default class User {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  phone: string;
  company: string;
  branch: string;
  job: string;
  rank: string;
  picture: string;

  constructor({
                id = "",
                email = "",
                first_name = "",
                last_name = "",
                date_of_birth = "",
                phone = "",
                company = "",
                branch = "",
                job = "",
                rank = "",
                picture = "",
              } = {}) {

    this.id = id;
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
    this.date_of_birth = date_of_birth;
    this.phone = phone;
    this.company = company;
    this.branch = branch;
    this.job = job;
    this.rank = rank;
    this.picture = picture;

  }
}