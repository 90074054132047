import {useTranslation} from "react-i18next";
import {styled, useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import GeneralServices from "../../services/GeneralService";
import GeneralInfo from "../../models/general";
import InfoCompanyContent
  from "../../components/company/General/InfoCompanyContent";
import InfoJuridicContent
  from "../../components/company/General/InfoJuridicContent";
import CustomButton from "../../components/global/button/Button";
import {BiSolidSave} from "react-icons/bi";

const GeneralPageContent = styled("div", {
    baseStyle: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "20px",
      width: "100%",
      overflowY: "auto",
    }
  },
)

const SectionContent = styled("div", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    minWidth: "300px",
    width: "70%",
  }
})

function CompanyGeneral() {

  const [loading, setLoading] = useState<boolean>(true);
  const [general, setGeneral] = useState<GeneralInfo>(new GeneralInfo);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const {t} = useTranslation();
  const toast = useToast();

  useEffect(() => {
    setLoading(true);
    GeneralServices.get()
      .then((res) => {
        setGeneral(res);
      }).finally(() => {
      setLoading(false);
    });
  }, []);

  const handleSaveAll = () => {
    setLoading(true)
    const areAllInputsEmpty = Object.values(general).every((value) => value.trim() === '');

    if (areAllInputsEmpty) {
      toast({
        title: t("labels.error"),
        description: t("pages.my_company.general.edit.empty"),
        status: "error",
        duration: 4500,
      })
      // return;
    }
    GeneralServices.update(general)
      .then((res) => {
        setGeneral(res)
        toast({
          title: t("pages.my_company.general.edit.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
      })
      .catch(
        (error) => {
          toast({
            title: t("labels.error"),
            description: t(`pages.my_company.general.edit.${error.detail}`),
            status: "error",
            duration: 4500,
          })
        }
      ).finally(() => {
      setLoading(false)
      setIsDirty(false)
    });
  }

  return (
    <GeneralPageContent>
      {isDirty && (
        <CustomButton
          icon={<BiSolidSave/>}
          onClick={handleSaveAll}
          style={{
            backgroundColor: "#2d2c2c",
            color: "#F5F3ED",
            width: "15%",
            minWidth: "100px",
            maxWidth: "175px",
            alignSelf: "flex-end",
            position: "fixed",
          }}
          textColor={"#F5F3ED"}
        >
          {t("labels.save")}
        </CustomButton>)}
      <SectionContent>
        <InfoCompanyContent general={general} setGeneral={setGeneral}
                            loading={loading} isDirty={isDirty}
                            setIsDirty={setIsDirty}/>
        <InfoJuridicContent general={general} setGeneral={setGeneral}
                            loading={loading}/>
      </SectionContent>


    </GeneralPageContent>
  )
}

export default CompanyGeneral;