import User from "../../../models/user";
import {Avatar, IconButton, styled, useDisclosure, useToast} from "@chakra-ui/react";
import {AiOutlineRight, AiOutlineUser} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import UserInformation from "../drawer/User/UserInformationDrawer/UserInformationDrawer";

const CardItem = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "70px",
    borderRadius: "8px",
    borderColor: "#918D86",
    borderWidth: "1px",
    marginTop: "5px",
    marginBottom: "5px",
  }
})

const CardItemLeft = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "20px",
  }
})

const CardText = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
  }
})

const CardTitle = styled("div", {
  baseStyle: {
    display: "flex",
    fontWeight: "regular",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
    fontSize: "15px",
    lineHeight: "20px",
  }
})

const CardSubtitle = styled("div", {
  baseStyle: {
    display: "flex",
    fontWeight: "regular",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
    fontSize: "12px",
    lineHeight: "14px",
  }
})

const CardItemRight = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "20px",
  }
})

interface UserCardProps {
  user: User;
  users: User[];
  setUsers: (users: User[]) => void;
}

function UserCard({
                    user,
                    users,
                    setUsers,
                  }: UserCardProps) {

  const {t} = useTranslation();
  const toast = useToast();

  const {isOpen, onOpen, onClose} = useDisclosure()

  const handleCopy = () => {
    navigator.clipboard.writeText(user.email)
      .then(r => {
        toast({
          title: t("pages.users_list.copy_success"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      });
  }

  return (
    <CardItem>
      <CardItemLeft>
        <Avatar
          size="md"
          name={user.first_name + " " + user.last_name}
          src={user.picture}
          bg="#F5F3ED"
          color="#918D86"
          icon={<AiOutlineUser fontSize='1.5rem'/>}
        />
        <CardText>
          <CardTitle>
            {user.first_name + " " + user.last_name}
          </CardTitle>
            <CardSubtitle>
              {user.email}
            </CardSubtitle>
        </CardText>
      </CardItemLeft>

      <CardItemRight>
        <IconButton
          variant='outline'
          color='#918D86'
          borderColor='#918D86'
          aria-label='Open user'
          fontSize='20px'
          icon={<AiOutlineRight/>}
          onClick={onOpen}
        />
      </CardItemRight>

      <UserInformation userID={user.id} isOpen={isOpen} onClose={onClose} users={users} setUsers={setUsers}/>
    </CardItem>
  )
}

export default UserCard;